import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export default function Footer(props) {
  let date = new Date;
  let currentYear = date.getFullYear();
  return (
    <div className="site-nav sm:fixed bottom-0 w-full bg-gray-100 py-4">
      <div class="container mx-auto sm:px-48">
        <div className="text-sm flex justify-between flex-col-reverse sm:flex-row items-center">
          <p className="text-gray-400 mt-2 sm:mt-0">&copy; Humanz Studioz LLP. 2016-{currentYear}</p>
          <ul className="flex">
            <li className=""><Link className="nav-link" to="/">Home</Link></li>
            <li className="ml-8"><Link className="nav-link" to="/privacy/">Privacy</Link></li>
            <li className="ml-8"><Link className="nav-link" to="/about/">About</Link></li>
            <li className="ml-8"><Link className="nav-link" to="/contact/">Contact</Link></li>
          </ul>
        </div>
      </div>
      <Helmet>
        <script src="/__/firebase/8.6.1/firebase-app.js"></script>
        <script src="/__/firebase/8.6.1/firebase-analytics.js"></script>
        <script src="/__/firebase/init.js"></script>
      </Helmet>
    </div>
  );
}